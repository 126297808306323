import React, { useState, useEffect, useContext } from "react";
import "./payBribeModal.css";
import CustomBox from "../../../../../components/customBox/CustomBox";
import { CustomButton } from "./../../../../../components/customButton/CustomButton";
import { PayBribeService } from "../../../../../services/bribe/PayBribeService";
import SignatureHelper from "../../../../../helpers/signatureHelper/SignatureHelper";
import { StakingContext } from "./../../../../../helpers/context/Context";
import { BribeValueService } from "../../../../../services/bribe/BribeValueService";

function PayBribeModal({ isShowing, setShowBribeModal, totalTickets}) {
    
  const { WalletId, setUserData, setErrorMessage, setIsSpinnerShowing } = useContext(StakingContext);
  const [ticketsSelected, setticketsSelected] = useState(totalTickets);
  const [BribePrice, setBribePrice] = useState(0);

  useEffect(() => {
    if(!isShowing) return;
    
    setticketsSelected(totalTickets);

    if(BribePrice != 0) return;
    BribeValueService()
    .then((data) => {
      setBribePrice(data.bribe_cost)})
    .catch((err) => {
      setErrorMessage(err.msg)});
  }, [isShowing]);

  const SubstractTicketHandler = () => {
    if (ticketsSelected - 1 <= 0) return;

    setticketsSelected(ticketsSelected - 1);
  };

  const AddTicketHandler = () => {
    if (ticketsSelected + 1 > totalTickets) return;

    setticketsSelected(ticketsSelected + 1);
  };

  const PayBribeHandler = async () => {
    
    try{
      setIsSpinnerShowing(true)
      const [msg, signature] = await SignatureHelper(WalletId);
        let res = await  PayBribeService(signature, WalletId, msg, ticketsSelected);
        setIsSpinnerShowing(false)

        setUserData((prevUserData) => ({
            ...prevUserData,
            ...{ fock: res.focks, speedingTickets: res.speeding_tickets, expected: res.expected, claimable: res.claimable },
        }));
        console.log(res);
        
        setTimeout(() => { //without the timeout it seems the modals glitch
          setErrorMessage(res.msg);
        }, 200);

        }catch(error){
          setIsSpinnerShowing(false)
          setShowBribeModal(false);
          setTimeout(() => { //without the timeout it seems the modals glitch
            setErrorMessage(error.msg);
          }, 200);
        }

    setShowBribeModal(false);
  };

  return (
    isShowing && 
      <div className="bribeModalWrapper">
        <div>
          <CustomBox lines={true} contentClass="bribeModal">
            <div className="bribeTitle">
              How many speeding tickets you wanna bribe?
            </div>
            <div className="bribeControlWrapper">
              <CustomButton
                text="-"
                customClass="rejectButton bribeButtonControl"
                onclick={SubstractTicketHandler}
              />
              <div className="ticketsToPay">{ticketsSelected}</div>
              <CustomButton
                text="+"
                customClass="rejectButton bribeButtonControl"
                onclick={AddTicketHandler}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {BribePrice} $fock each ticket
            </div>
          </CustomBox>
          <div className="bribeButtonWrapper">
            <CustomButton
              text="CANCEL"
              customClass="rejectButton"
              onclick={() => setShowBribeModal(false)}
            />
            <CustomButton text="PAY" onclick={PayBribeHandler} />
          </div>
        </div>
      </div>
  );
}

export default PayBribeModal;
