import React from 'react'
import "./customBox.css"

function CustomBox({lines = false, customClass = "", children, contentClass}) {
  return (
    <div className={`customBox ${customClass}`}>
    <div className={`customBoxLine ${lines ? "colorLines" : ""}`}>
    </div>
      <div className={`customBoxContent ${contentClass}`}>
          {children}
      </div>
      <div className={`customBoxLine ${lines ? "colorLines" : ""}`}></div>
    </div>
  )
}

export default CustomBox