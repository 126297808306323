import { getConfig, URI } from "../../helpers/netWorking/Constants";
import { customFetch } from "../../helpers/netWorking/CustomFetch";

export const SignatureMessageService = async (walletId) => {

  try {

        var configs = getConfig("GET");

        const response = await customFetch(`${URI}/message?address=${walletId}`, configs);

        return response;

      } catch (e) {
        console.log(e)
        throw e;
      }
}
