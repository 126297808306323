import React, { useContext, useState, useRef } from "react";
import { StakingContext } from "../../../../../helpers/context/Context";
import { CustomButton } from "../../../../../components/customButton/CustomButton";
import { ClaimService } from "../../../../../services/claim/ClaimService";
import CustomModal from "./../../../../../components/customModal/CustomModal";
import SignatureHelper from "../../../../../helpers/signatureHelper/SignatureHelper";
import { ParseIntWithDots } from "../../../../../helpers/parseIntWithDots/ParseIntWithDots";
import PayBribeModal from "../../speedingTickets/payBribeModal/PayBribeModal";
import { env } from "../../../../../env";

export const ClaimButton = () => {
  const { UserData, setUserData, setIsSpinnerShowing, WalletId } =
    useContext(StakingContext);

  const [ShowModal, setShowModal] = useState(false);
  const [ShowResponseModal, setShowResponseModal] = useState(false);
  const [ClaimResult, setClaimResult] = useState("");
  const [ShowBribeModal, setShowBribeModal] = useState(false);

  console.log("env.REACT_APP_ENV ", env.REACT_APP_ENV)
  const refSelect = useRef("0");
  const refCheckBox = useRef(false);

  const ResultTexts = {
    NORMAL: (earnedFocks) => `Mission Successful
    you earned ${ParseIntWithDots(earnedFocks)} $fock
    No casualties, clean as fock.`,
    MISSION_FAILED: (earnedFocks) => `Mission Failed
    Unfortunately you lost 50% of the expected $fock.`,
    DUPLICATED: (earnedFocks) => `Mission Successful
    you earned twice as expected.
    You are a focking genius.`,
  };

  const ResultTextsPenalty = {
    NORMAL: (earnedFocks) => `Mission Successful
    you earned ${ParseIntWithDots(earnedFocks)} $fock
    but you got a speeding ticket.`,
    MISSION_FAILED: (earnedFocks) => `Mission Failed
    Unfortunately you lost 50% of the expected $fock 
    and you got a speeding ticket.`,
    DUPLICATED: (earnedFocks) => `Mission Successful
    you earned twice as expected,
    but you got a speeding ticket.`,
  };

  const ConfirmClaim = async () => {
    setIsSpinnerShowing(true);

    try {
      let resultForce = refSelect.current.value;
      let wasPenaltyForce = refCheckBox.current.checked;

      const [msg, signature] = await SignatureHelper(WalletId);
      let res = await ClaimService(
        WalletId,
        signature,
        msg,
        resultForce,
        wasPenaltyForce
      );
      console.log(res);

      setUserData((prevUserData) => ({
        ...prevUserData,
        ...{
          claimable: 0,
          fock: res.total_focks,
          speedingTickets: res.speeding_tickets,
          expected: res.expected,
          nextClaim: res.next_claim,
        },
      }));

      if (res.wasPenalty) {
        setClaimResult(ResultTextsPenalty[res.result](res.claimed_focks));
      } else {
        setClaimResult(ResultTexts[res.result](res.claimed_focks));
      }
    } catch (er) {
      setClaimResult(er.msg ?? "Oops something went wrong");
      console.log(er);
    }
    setShowResponseModal(true);
    setShowModal(false);
    setIsSpinnerShowing(false);
  };

  const ClaimHandler = () => {
    setShowModal(true);
  };

  const payBribeHandler = () => {
    setShowBribeModal(true);
    setShowResponseModal(false);
  };

  return (
    <>
      <PayBribeModal
        isShowing={ShowBribeModal}
        setShowBribeModal={setShowBribeModal}
        totalTickets={UserData.speedingTickets}
      />
      <CustomModal
        message={"YOU ARE ABOUT TO CLAIM"}
        footer={`EST ${ParseIntWithDots(UserData.claimable)} $FOCK`}
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        rejectButton={true}
      >
      {env.REACT_APP_ENV == "dev" && <div> 
          <select ref={refSelect}>
            <option value="0">NORMAL</option>
            <option value="1">MISSION_FAILED</option>
            <option value="2">DUPLICATED</option>
          </select>

          <div>
            <input ref={refCheckBox} type="checkbox" /> was penalty
          </div>
        </div>}

        <CustomButton text="CONFIRM" onclick={ConfirmClaim} />
      </CustomModal>
      <CustomModal
        message={ClaimResult}
        okButton={true}
        ShowModal={ShowResponseModal}
        setShowModal={setShowResponseModal}
      >
        {ClaimResult.includes("speeding") && (
          <CustomButton text="PAY BRIBE" onclick={payBribeHandler} />
        )}
      </CustomModal>
      <CustomButton
        onclick={ClaimHandler}
        text={
          !!UserData.nextClaim
            ? UserData.nextClaim + " DAYS TO CLAIM"
            : "CLAIM"
        }
        customClass="rejectButton"
        disabled={UserData.claimable == 0}
      />
    </>
  );
};
