import React from 'react'
import "./footer.css"

export const Footer = () => {
  return (
    <>
        <p className='footerBottom'></p>
        <div className='socialMediaWrapper'>
              <a href="https://twitter.com/TheAlienBoyNFT" target="_blank">
                <img src={require("../../assets/img/socialMedia/twitter.png")} alt="twitter"/>
              </a>
              <a href="https://discord.com/invite/TheAlienBoy"  target="_blank">
                <img src={require("../../assets/img/socialMedia/discord.png")} alt="discord"/>
              </a>
              <a href="https://opensea.io/collection/thealienboy"  target="_blank">
                <img src={require("../../assets/img/socialMedia/opensea.png")} alt="openSea"/>
              </a>
              <a href="https://www.instagram.com/thealienboy_nft/"  target="_blank">
                <img src={require("../../assets/img/socialMedia/instagram.png")} alt="instagram"/>
              </a>
            </div>
    </>
  )
}
