import React, { useState } from "react";
import "./customModal.css";
import CustomBox from "../customBox/CustomBox";
import { CustomButton } from "../customButton/CustomButton";
import { createPortal } from "react-dom";

function CustomModal({
  message = "",
  footer= "",
  children,
  okButton = false, rejectButton = false,
  ShowModal, setShowModal
}) {

  return (
    ShowModal && (
      createPortal(
      <div className="customModalWrapper">
        <div>
          <CustomBox lines={true}>
            <div className="customModalContent">
              <div style={{whiteSpace: "pre-line" }}>{message}</div>
              <div className="customModalFooter">{footer}</div>
            </div>
          </CustomBox>
          <div className="customModalButtonWrapper">
            {rejectButton && <CustomButton text="REJECT" onclick={() => setShowModal(false)} customClass="rejectButton" />}
            {children}
           {okButton && <CustomButton text="OK" onclick={() => setShowModal(false)} />}
          </div>
        </div>
      </div>, document.getElementById("modal"))
    )
  );
}

export default CustomModal;
