import { getConfig, URI } from "../../helpers/netWorking/Constants";
import { customFetch } from "../../helpers/netWorking/CustomFetch";

export const PayBribeService = async (signature, address, message, speedingTickets) => {

  try {
        var body = {
          walletId: address,
          signature: signature,
          message: message,
          toPay: speedingTickets
        }

        var configs = getConfig("POST", body);
        const response = await customFetch(`${URI}/bribe`, configs);

        return response;
      } catch (e) {
        throw e;
      }
}