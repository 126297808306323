import { React, useEffect, useContext, useState } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3Modal from "web3modal";
import "./connectWallet.css";
import { StakingContext } from '../../../../helpers/context/Context';

import {env} from '../../../../env';

const ConfigChainId =  env.REACT_APP_NETWORK == "rinkeby" ? "0x4" : "0x1";
console.log("network: ",env.REACT_APP_NETWORK);
function ConnectWallet() {

  const {setIsPageEnabled, setWalletId, setErrorMessage } = useContext(StakingContext); //if you r connected and in the eth net is true

  const [web3Modal, setWeb3Modal] = useState(null);
  const [IsEthNetwork, setIsEthNetwork] = useState(true); //if you r in the eth net is true
  const [IsWalletConnected, setIsWalletConnected] = useState(false); //virtual connection
  const [showEthAlert, setshowEthAlert] = useState(false); //show the alert if you arent in the eth net


  async function connectWallet() {
    try{

      const provider = await web3Modal.connect();
      console.log("provider ", provider)
      var accounts;
      
      if(provider.isWalletConnect){
        accounts = provider.accounts
      }else{

        accounts = await provider.request({
          method: "eth_requestAccounts"
        })
      }

      setWalletId(accounts[0]);
      setshowEthAlert(provider.chainId != ConfigChainId);
      setIsEthNetwork(provider.chainId == ConfigChainId);
      setIsWalletConnected(true);
      
    provider.on("accountsChanged", (accounts) => { //listeners
      setIsWalletConnected(accounts.length != 0);
      console.log(accounts)
      
      if(accounts.length != 0){
        setWalletId(accounts[0]);
      }
    });
    
    provider.on("chainChanged", (chainId) => {
      setshowEthAlert(chainId != ConfigChainId)
      setIsEthNetwork(chainId == ConfigChainId)
    });
  }catch(er){
    console.log("er", er)
    setErrorMessage("Login rejected by user");
  }
  }

  const disconnectWallet = async () => {
    try{
      const provider = await web3Modal.connect();
      provider.removeAllListeners("accountsChanged")
      provider.removeAllListeners("chainChanged")
      setWalletId("")
      setIsWalletConnected(false);
      setshowEthAlert(false)
    }catch{
    }
  }

  useEffect(() => {
    conectionChange()
  }, [IsWalletConnected,  IsEthNetwork])

    const conectionChange = async () => {
       if(IsWalletConnected && !IsEthNetwork){
        const provider = await web3Modal.connect();
        provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: ConfigChainId }],
      });
    }
    setIsPageEnabled(IsWalletConnected && IsEthNetwork)
    }
  useEffect(() => {
    if(web3Modal && web3Modal.cachedProvider){
      connectWallet()
    }
  }, [web3Modal])

  useEffect(() => {

    const providerOptions = {
        coinbasewallet: {
          package: CoinbaseWalletSDK, // Required
          options: {
            appName: "The Alien Boy Photobooth", // Required
            infuraId: "597f81dddea0492ca599a7e789349477", // Required
            chainId: 1, // Optional. It defaults to 1 if not provided
          }
        },
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: "597f81dddea0492ca599a7e789349477",
            }
        },
        fortmatic: {
            package:  window.Fortmatic,
            options: {
                key: "pk_live_C95B642EE4E358FD" // production
            }
        }
    };

    var _Web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        network: env.REACT_APP_NETWORK || "mainnet", // rinkeby | mainnet
        providerOptions, // required
    });

    setWeb3Modal(_Web3Modal);

  }, []);

  return (
    <>
      <div className={`EthAlert ${showEthAlert ? "EthAlertOpen" : ""}`}> 
       Oops! Please change to the {env.REACT_APP_NETWORK || "mainnet"} network
        <div onClick={() => setshowEthAlert(false)}>x</div>
        </div>
      <div className="connectWalletWrapper">
      <button onClick={IsWalletConnected ? disconnectWallet : connectWallet}
              className={"connectWalletBtn"}>{IsWalletConnected ? "Disconnect" :  "Connect"  }
      </button>
      </div>
    </>
  );
}

export default ConnectWallet;
