import React from 'react'
import "./dottedData.css"


function DottedData({title, data}) {
  return (
    <div className="dottedMiddleContainer">
        <div>{title}</div>{data}
    </div>
  )
}

export default DottedData