import React, {useEffect, useContext, useState} from "react";
import "./home.css";
import Mission from './components/mission/Mission';
import SpeedingTickets from './components/speedingTickets/SpeedingTickets';
import { StakingContext } from './../../helpers/context/Context';
import { UserService } from './../../services/user/UserService';
import FockData from './components/header/fockData/FockData';
import StakedData from './components/header/stakedData/StakedData';
import StakingButton from "./components/header/stakingButton/StakingButton";
import WalletNotConnected from './../walletNotConnected/WalletNotConnected';
import { ClaimButton } from "./components/header/claimButton/ClaimButton";

export const Home = () => {

  const { WalletId, setIsSpinnerShowing, setErrorMessage, setUserData, IsPageEnabled} = useContext(StakingContext);
  const [AlienCounts, setAlienCounts] = useState({girl: 0, boy: 0, ufo: 0, doogle: 0});


  const ResetData = () => {
    setUserData({claimable: 0, expected: 0, stakedAt: null, fock: 0, speedingTickets: 0, nextClaim: 0});
    setAlienCounts({girl: 0, boy: 0, ufo: 0, doogle: 0});
  }

  useEffect(() => {
      if(!WalletId){
        ResetData()
        return;
      }

      setIsSpinnerShowing(true);

      UserService(WalletId)
        .then(data =>{
          console.log("data", data)
          setIsSpinnerShowing(false)
          setAlienCounts({girl: data.girl_count, boy: data.boy_count, ufo: data.ufo_count, doogle: data.doogle_count})
          setUserData({claimable: data.claimable,
             expected: data.expected,
             stakedAt: data.staked_at,
            fock: data.focks, 
            speedingTickets: data.speeding_tickets,
            nextClaim: data.next_claim});
        })
        .catch(er => {
          setErrorMessage(er.msg)
          setIsSpinnerShowing(false)
        })
    
  }, [WalletId])

  return (
    IsPageEnabled ? (
      <div className="home">
      <div className="homeHeader">
        <div>
          <StakedData AlienCounts={AlienCounts}/>
          <StakingButton MissionCount={AlienCounts.boy + AlienCounts.girl + AlienCounts.doogle }  />
        </div>
        <div>
          <FockData />
          <ClaimButton/>
        </div>
      </div> 
      <Mission MissionCount={AlienCounts.boy + AlienCounts.girl + AlienCounts.ufo + AlienCounts.doogle } />
      <SpeedingTickets />
    </div>
    ) : (
      <WalletNotConnected />
    )
  );
};
