import React, {useState, useContext, useEffect} from 'react'
import ticketsIMG from "../../../../assets/img/tickets.gif";
import CustomBox from '../../../../components/customBox/CustomBox'
import { CustomButton } from '../../../../components/customButton/CustomButton';
import "./speedingTickets.css"
import PayBribeModal from './payBribeModal/PayBribeModal';
import { StakingContext } from './../../../../helpers/context/Context';


function SpeedingTickets() {
  
  const [ShowBribeModal, setShowBribeModal] = useState(false);

  const {UserData} = useContext(StakingContext);

  return (
  <div>
  <PayBribeModal isShowing={ShowBribeModal} setShowBribeModal={setShowBribeModal} totalTickets={UserData.speedingTickets}/>
    <CustomBox lines={true}>
       <div className="speedingWrapper">
        <div className="speedingDetail">
          <div>SPEEDING<br/>TICKETS</div>
          <div>{UserData.speedingTickets}</div>
        </div>
        <img src={ticketsIMG} />
        <CustomButton text="BRIBE" customClass="rejectButton speedingButton" onclick={()=> setShowBribeModal(true)} disabled={UserData.speedingTickets == 0}/>
      </div>
    </CustomBox>
  </div>
  )
}

export default SpeedingTickets