import { getConfig, URI } from "../../helpers/netWorking/Constants";
import { customFetch } from "../../helpers/netWorking/CustomFetch";

export const StakingService = async (signature, address, message) => {
  try {
        var body = {
          walletId: address,
          signature: signature,
          message: message
        }

        var configs = getConfig("POST", body);
        const response = await customFetch(`${URI}/stake`, configs);

        return response;
      } catch (e) {
        throw e;
      }
}

export const UntakingService = async (signature, address, message) => {

        try {
          var body = {
            walletId: address,
            signature: signature,
            message: message
          }

        var configs = getConfig("PUT", body);
    
        const response = await customFetch(`${URI}/unstake`, configs);

        return response;
      } catch (e) {
        throw e;
      }
}
