import { SignatureMessageService } from '../../services/signatureMessage/SignatureMessageService';
import { ethers } from 'ethers';

const SignatureHelper =  async(WalletId) =>{

    try{

        var signature, msg;
        signature = sessionStorage.getItem("signature");

        if(signature){
            msg = sessionStorage.getItem("msg");
            const address = await ethers.utils.verifyMessage(msg, signature);

            if(address.toLowerCase() == WalletId.toLowerCase())
             return [msg, signature] 
        }

        ({msg} = await SignatureMessageService(WalletId))
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        signature = await signer.signMessage(msg);

        sessionStorage.setItem('signature', signature);
        sessionStorage.setItem('msg', msg);


        return [msg, signature];

    }catch(er){
        console.log(er)
        throw {msg: "Signature rejected"};
    }


}

export default SignatureHelper