export const customFetch = async (url, configs, ms = 45000) => {
    try{
        
        var controller = new AbortController();
        var signal = controller.signal;

        setTimeout(() => {
            controller.abort();
        }, ms);

        configs = {...configs, signal}

        let response = await fetch(url, configs);
        
        if(response.status == 404){
            throw {msg: 'Oops 404 this is not the path'};
        }
        
        let data = await response.json();
        if (response.ok) return data;

        if(data.msg || data.message)
            throw data;
        
        throw {msg: 'Something went wrong'};

    }catch(e){
            throw {error: e, msg: e.message ?? e.msg ?? "Something went wrong"};
    }
    
    
};
    
