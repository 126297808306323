import { getConfig, URI } from "../../helpers/netWorking/Constants";
import { customFetch } from "../../helpers/netWorking/CustomFetch";
import { env } from "../../env";
export const ClaimService = async (walletId, signature, message, result, wasPenalty) => {

  try {
        let body = {
            walletId: walletId,
            signature: signature,
            message: message
        }

        if(env.REACT_APP_ENV == "dev"){
          body.result = result;
          body.hasTicket = wasPenalty
        }

        var configs = getConfig("POST", body);

        const response = await customFetch(`${URI}/claim`, configs);

        return response;

      } catch (e) {
        throw e;
      }
}
