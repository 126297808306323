import { getConfig, URI } from "../../helpers/netWorking/Constants";
import { customFetch } from "../../helpers/netWorking/CustomFetch";

export const BribeValueService = async () => {

  try {

        var configs = getConfig("GET");

        const response = await customFetch(`${URI}/bribe`, configs);

        return response;

      } catch (e) {
        throw e;
      }
}
